/**
 * @file  登录
 * @author chengong03(chengong03@baidu.com)
 * @date 2019-01-14
 */

import {get, commit} from './globalState';
import {isSC, isBB, isBBMp} from './ua';
import {invokeSC, invokeBB} from './app';

/**
 * 登录状态信息
 *
 * @const
 * @type {Object}
 */
const PASS_DOMAIN = 'https://passport.baidu.com';
/**
 * wise 登录地址（优先短信验证码登录）
 *
 * @const
 * @type {string}
 */
const WISE_LOGIN_URL = PASS_DOMAIN + '/passport/login?sms=1&tpl=lvyou&u=';

/**
 * wise 注册地址
 *
 * @const
 * @type {string}
 */
const WISE_REG_URL = PASS_DOMAIN + '/passport/reg?tpl=lvyou&u=';

/**
 * wise 登出地址
 *
 * @const
 * @type {string}
 */
const WISE_LOGOUT_URL = PASS_DOMAIN + '/?logout&tpl=lvyou&u=';

/**
 * 登录地址
 */
export const loginUrl = WISE_LOGIN_URL;

/**
 * 注册地址
 */
export const regUrl = WISE_REG_URL;

/**
 * 登出地址
 */
export const logoutUrl = WISE_LOGOUT_URL;

/**
 * 更新登录信息
 * 比如用户登录后回退，则需要将后端 Ajax 中的 loginInfo 重置下
 *
 * @param {Object} loginInfo 登录信息
 */
export function refreshLogin(loginInfo) {
    let tplData = window._tplData || {};
    let newLoginStatus = {
        ...tplData.loginInfo,
        ...loginInfo
    };
    commit('loginInfo', newLoginStatus);

    let newTplData = {
        ...tplData,
        loginInfo: newLoginStatus
    };
    window._tplData = newTplData;

}

/**
 * 登录
 *
 * @param {Object} params 传参
 */
export function login(params) {
    // 手百配置方法：http://wiki.baidu.com/pages/viewpage.action?pageId=685216717
    // 简搜登录
    if (isSC()) {
        scLogin();
    }
    // 手百登录（不包括手百小程序）
    else if (isBB() && !isBBMp()) {
        bbLogin(params);
    }
    // html 登录
    else {
        h5Login(params);
    }
}

/**
 * 登出
 *
 * @param {string} url 登出后跳转的url
 */
export function logout(url) {
    window.location.href = logoutUrl + encodeURIComponent(url ? url : window.location.href);
    return;
}

/**
 * 跳到站外某个页面，并且需要强登录
 * 跳到站内不用此方法，直接在 router meta 里配置即可
 *
 * @param {string|Function} url 登录的地址，如果传函数，则用户自己控制登录完的逻辑
 */
export function loginJump(url) {
    let isLogin = get('loginInfo').isLogin;

    if (!isLogin) {
        login();
        return;
    }

    if (typeof url === 'function') {
        url();
        return;
    }

    window.location.href = url;
}

/**
 * html 登录
 *
 * @param {Object} params 传参
 */
export function h5Login(params = {}) {
    let paramsList = [];
    Object.keys(params).forEach(key => {
        paramsList.push(`&${key}=${params[key]}`);
    });
    let ques = window.location.href.indexOf('?') === -1 && paramsList.length ? '?' : '';
    window.location.href = WISE_LOGIN_URL + encodeURIComponent(
        window.location.href + ques
        + (ques ? paramsList.join('').slice(1) : paramsList.join('')));

}

/**
 * 简搜登录 SearchCraft
 * http://agroup.baidu.com/simplesearchapi/md/article/989442
 */
export function scLogin() {
    // 回调函数
    window.BaiduSCPassCallBack = function (ret = {}) {

        // 登录错误时，尝试使用 h5 登录
        if (ret.status) {
            h5Login();
            return;
        }
        
        // 登录成功重刷页面
        window.location.reload();
    };

    // 调起简搜登录能力
    invokeSC(
        {
            func: 'invokeModule',
            moduleName: 'BaiduPassport',
            options: {
                // 取值 login | logout | isLoggedIn
                action: 'login'
            },
            callback: 'window.BaiduSCPassCallBack'
        },
        function () {
            // 不支持端能力，调用 h5 登录
            h5Login();
        }
    );
}

/**
 * 手百登录 BaiduBox
 * http://invoker.baidu.com/invokers?id=546d74b92870cf906ddafc9b
 */
export function bbLogin(params = {}) {
    // 回调函数
    window.BaiduBBPassCallBack = function (ret) {
        // ret 0：登录成功；1：失败
        if (ret) {
            return;
        }
        // 回传参数处理
        let paramsList = [];
        Object.keys(params).forEach(key => {
            paramsList.push(`&${key}=${params[key]}`);
        });
        let ques = window.location.href.indexOf('?') === -1 && paramsList.length ? '?' : '';
        // 登录成功重刷页面
        window.location.href = window.location.href + ques
            + (ques ? paramsList.join('').slice(1) : paramsList.join(''));
    };

    const funcName = 'window.BaiduBBPassCallBack';
    const options = JSON.stringify({
        tpl: 'b2b',
        // 1：支持第三方登录；0或者不设置：不支持第三方登录
        // third_login: '0',
        // sms 为短信登录；username 为用户名密码登录，缺省使用框内默认的规则
        // login_type: 'sms',
        func: funcName
    });

    invokeBB(
        // iOS
        [
            'account',
            {
                action: 'logindialog',
                params: options
            },
            ''
        ],
        // Andiord
        [
            'Bdbox_android_account',
            'loginDialog',
            [
                options,
                funcName
            ]
        ],
        function () {
            // 不支持端能力，调用 h5 登录
            h5Login();
        }
    );
}
