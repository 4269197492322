/**
 * @file 入口文件
 * @author wangjaiqi(wangjiaqi17@baidu.com)
 * @date 2024-04-26
 */

import initMApp from './initMApp';
import '@/assets/css/index.less';
import router from './router';

initMApp(router);
