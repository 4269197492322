/**
 * @file Ajax
 * @author chengong03(sunyueran@baidu.com)
 * @date 2018-11-28
 */
import Vue from 'vue';
import qs from 'qs';
import axios from 'axios';
import {NO_LOGID_AJAX} from '@/conf';
import {getQuery, getAccessid} from '@/utils/util';
import {refreshLogin} from '@/utils/verify';
import {get, commit} from './globalState';
import {setLog} from '@/utils/log';

// 构建默认参数
let defaultParams = function () {
    const accessid = getAccessid();
    const { rid } = getQuery();
    let params = {
        // 前端 mock 时根据这个字段去区分是否为 ajax 请求，后端的 jump 接口也需要用
        ajax: 1,
        accessid: accessid.join(''),
        device: 'wise'
    };
    rid && (params.rid = rid);
    return params;
};

/**
 * 当前请求是否在 NO_LOGID_AJAX 列表中
 *
 * @param {string} url url
 * @return {boolean} 是否在 NO_LOGID_AJAX 列表中
 */
function inNoLogidList(url) {
    for (let item of NO_LOGID_AJAX) {
        if (url && url.indexOf(item) === 0) {
            return true;
        }
    }

    return false;
}

// 添加请求拦截器
axios.interceptors.request.use(
    function (config) {
        // 接口请求开始
        const sendDate = new Date().getTime();
        config.interFace_startTime = sendDate;
        const originUrl = config.url;
        let prefix = config.url.indexOf('?') === -1 ? '?' : '&';
        // 所有请求附带默认参数
        let queryString = qs.stringify(defaultParams());
        if (queryString) {
            config.url += prefix + queryString;
        }

        // 添加 csrftoken 防止crsf漏洞
        let csrftoken = window._tplData && window._tplData.trace.csrftoken;
        // abtest上报数据接口不添加csrftoken。之后删除abtest上报接口时，需要删除这个逻辑
        if (csrftoken && originUrl.indexOf('/api/open/v1/report') === -1) {
            // 将csrftoken放到请求头发送给服务器
            config.headers.csrftoken = csrftoken;
        }
        config.cancelToken = new axios.CancelToken(cancel => {
            let requestArr = get('requestArr');
            requestArr.push(cancel);
            commit('requestArr', requestArr);
        });
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    response => {
        // 接口请求结束
        const {data, config = {}, request} = response;
        const trace = data.trace || {};
        const logid = data.traceid;
        const loginInfo = data.loginInfo;

        // 如果有 logid，则重写全局 logid
        // 方便统计取最新的 logid，正常打点不需要关注 logid
        if (logid) {
            // 如果此请求不在 NO_LOGID_AJAX 列表里，则需要修改 logId
            let changeLogId = !inNoLogidList(config.url);
            
            if (changeLogId) {
                setLog({
                    logid
                })
            }
        }

        // 页面数据追踪
        if (Array.isArray(window.traceMap)) {
            window.traceMap.push({
                url: request.responseURL,
                trace
            });
        }
        // 如果有登录状态，则更新登录状态
        if (loginInfo) {
            refreshLogin(loginInfo);
        }

        return data;
    },
    function (error) {
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$http = axios;

export default axios;
