/**
 * @file 简易的全局响应式状态，用于跨组件通信
 * @author maomingyang@baidu.com
 * @date 2019.05.08
 */
import Vue from 'vue';

/**
 * 全局响应式状态，请先买票后上车
 *
 * @const
 * @type {Object}
 */
const STATE = Vue.observable({
    // 登录状态信息
    loginInfo: {},
    requestArr: [],
});

/**
 * 获取状态
 *
 * @param  {string} field 字段名
 * @return {any}          状态值
 */
export const get = field => STATE[field];

/**
 * 修改状态
 *
 * @param  {string} field 字段名
 * @param  {any} value    状态值
 */
export const commit = (field, value) => {
    STATE[field] = value;
};

Vue.prototype.$globalState = {get, commit};
