/**
 * @file 用户停留时长打点mixin
 * @author wangjiaqi(wangjiaqi17@baidu.com)
 * @date 2022-06-13
 */
import { log } from '@/utils/log';
import store from '@/store';

export default {
    beforeCreate() {
        document.addEventListener('visibilitychange', () => {
            // 页面不在可视区域时
            if (document.visibilityState === 'hidden' && !store.state.durationState) {
                this.setDurationLog();
            }
            // 页面回到可视区域时 设置页面停留初始时间
            else if (document.visibilityState === 'visible') {
                store.commit('SET_PAGE_START_TIME', new Date().getTime());
                // 为避免重复打点，在用户离开时置一个标志位 TRUE，回来时再置回 FALSE
                store.commit('SET_DURATION_STATE', false);
            }
        });
        // 兼容 safari 下, 当 visibleStateState 属性的值转换为 hidden 时，Safari 不会按预期触发visibilitychange的问题
        window.addEventListener('pagehide', () => {
            if (!store.state.durationState) {
                this.setDurationLog();
            }
        });
        // 跳出站内的情况，包括前进、后退、以及点击外链跳转以及关闭页签
        window.addEventListener('beforeunload', () => {
            if (!store.state.durationState) {
                this.setDurationLog();
            }
        });
    },
    methods: {
        // 用户停留时长打点
        setDurationLog() {
            log({
                act: 'duration',
                statetime: new Date().getTime() - store.state.pageStartTime
            })
            // 为避免重复打点，在用户离开时置一个标志位 TRUE，回来时再置回 FALSE
            store.commit('SET_DURATION_STATE', true);
        }
    }
};
