/**
 * @file dom 处理
 * @author chengong03(chengong03@baidu.com)
 * @date 2018-11-23
 */

// ****************************************************
// *                  class                           *
// ****************************************************

/**
 * 为目标元素添加className
 *
 * @public
 * @param {HTMLElement} element 目标元素
 * @param {string} className 要添加的className
 *
 * @return {HTMLElement} 目标元素
 */
export function addClass(element, className) {
    // 优先使用classList. 在iOS 5, Android 3 之后开始支持
    if (element.classList) {
        element.classList.add(className);
    }
    else {
        let classes = element.className
            ? element.className.split(/\s+/)
            : [];

        for (let i = 0, n = classes.length; i < n; i++) {
            if (classes[i] === className) {
                return element;
            }
        }

        classes.push(className);
        element.className = classes.join(' ');
    }

    return element;
}

/**
 * 移除目标元素的className
 *
 * @public
 * @param {HTMLElement} element 目标元素
 * @param {string} className 要移除的className
 *
 * @return {HTMLElement} 目标元素
 */
export function removeClass(element, className) {
    if (element.classList) {
        element.classList.remove(className);
    }
    else {
        let classes = element.className
            ? element.className.split(/\s+/)
            : [];

        for (let i = 0, n = classes.length; i < n; i++) {
            if (classes[i] === className) {
                classes.splice(i, 1);
                i--;
            }
        }
        element.className = classes.join(' ');
    }

    return element;
}

/**
 * 判断元素是否拥有指定的className
 *
 * @public
 * @param {HTMLElement} element 目标元素
 * @param {string} className 要判断的className
 *
 * @return {boolean} 是否拥有指定的className
 */
export function hasClass(element, className) {
    // 方法名用 hasClass，是因为 contains 在 dom 模块中可能引起歧义
    if (element.classList) {
        return element.classList.contains(className);
    }

    let classes = element.className.split(/\s+/);
    for (let i = 0, n = classes.length; i < n; i++) {
        if (classes[i] === className) {
            return true;
        }
    }

    return false;
}

// ****************************************************
// *                  selector                        *
// ****************************************************

/**
 * 根据id获取指定的DOM元素
 *
 * @public
 * @param {string|HTMLElement} id 元素的id或DOM元素
 * @return {HTMLElement|null} 获取的元素，找不到时返回null
 */
export function g(id) {
    if (!id) {
        return null;
    }

    return typeof id === 'string' ? document.getElementById(id) : id;
}

/**
 * 根据选择器获取指定DOM元素
 *
 * @public
 * @param {string} selector 元素的selector
 * @param {HTMLElement=} context 上下文
 * @return {HTMLElement|null} 获取的元素，找不到时返回null
 */
export function query(selector, context) {
    if ('string' !== typeof selector) {
        return selector;
    }

    context = context || document.body;

    return context.querySelector(selector);
}

/**
 * 根据选择器选择DOM元素列表
 *
 * @public
 * @param {string} selector 元素的selector
 * @param {HTMLElement=} context 上下文
 * @return {Array} 获取的元素列表，找不到时为空数组
 */
export function queryAll(selector, context) {
    if (Array.isArray(selector)) {
        return selector;
    }

    context = context || document.body;

    let nodeList = context.querySelectorAll(selector);

    return Array.prototype.slice.call(nodeList);
}

/**
 * 判断DOM元素与选择器是否匹配
 *
 * @param {HTMLElement} element 目标DOM元素
 * @param {string} selector 待判断的selector
 * @return {boolean} 是否匹配
 */
export function matches(element, selector) {
    let proto = Element.prototype;
    let matches = proto.matches
        || proto.webkitMatchesSelector
        || proto.mozMatchesSelector
        || proto.msMatchesSelector;

    if (matches) {
        return matches.call(element, selector);
    }

    let elements = queryAll(selector, element.parentNode);
    for (let i = 0; i < elements.length; i++) {
        if (elements[i] === element) {
            return true;
        }
    }
    return false;
}


// ****************************************************
// *                  traversal                       *
// ****************************************************

/**
 * 获取元素的子节点
 *
 * @public
 * @param {HTMLElement} element 目标元素
 * @return {Array.<HTMLElement>} 子节点
 */
export function children(element) {
    let res = [];

    let items = element.children;
    /* eslint-disable no-cond-assign */
    for (let i = 0, item; item = items[i]; i++) {
        if (item.nodeType === 1) {
            res.push(item);
        }
    }
    /* eslint-enable no-cond-assign */

    return res;
}

/**
 * 查找第一个匹配条件的祖先元素
 *
 * @param {HTMLElement} element 目标元素
 * @param {string} selector 查询条件
 * @param {HTMLElement=} context 遍历范围
 * @return {HTMLElement|null} 匹配到的节点，找不到时返回null
 */
export function closest(element, selector, context) {
    context = context || document;

    do {
        if (matches(element, selector)) {
            return element;
        }

        if (element === context) {
            return null;
        }
    }
    while ((element = element.parentNode) && element !== document);

    return null;
}

// ****************************************************
// *                  position                        *
// ****************************************************

/**
 * 获取 scrollTop
 *
 * @return {number} scrollTop 高
 */
export function getScrollTop() {
    return document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
}

/**
 * 设置滚动高度
 *
 * @param {number} offset 高度
 */
export function setScrollTop(offset) {
    document.body.scrollTop = offset;
    document.documentElement.scrollTop = offset;
}

/**
 * 获取 clientWidth
 *
 * @return {number} clientWidth 屏幕宽
 */
export function getClientWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

/**
 * 获取 clientHeight
 *
 * @return {number} clientHeight 屏幕高
 */
export function getClientHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

/**
 * 获取元素的相对位置
 *
 * @public
 * @param {HTMLElement} element 目标元素
 * @param {HTMLElement=} offsetEle 相对元素
 * @return {Object}
 */
export function position(element, offsetEle) {
    let res = {left: 0, top: 0};
    let pos = element && element.getBoundingClientRect && element.getBoundingClientRect();

    if (offsetEle) {
        let fixPos = offsetEle.getBoundingClientRect();
        res.left = pos.left - fixPos.left;
        res.top = pos.top - fixPos.top;
    }
    else if (pos){
        res.left = pos.left + Math.max(
            document.documentElement.scrollLeft,
            document.body.scrollLeft
        );
        res.top = pos.top + Math.max(
            document.documentElement.scrollTop,
            document.body.scrollTop
        );
    }

    return res;
}

// ****************************************************
// *                  css                             *
// ****************************************************

/**
 * 检测当前浏览器是否支持某个属性或者某个属性值
 *
 * @param  {string}  property 检测的CSS属性名
 * @param  {string}  value    检测的CSS属性值
 * @return {boolean}          是否支持
 */
let element = null;
export function isSupportCssProperty(property, value) {
    if (!element) {
        element = document.createElement('div');
    }
    if (property in element.style) {
        if (value) {
            element.style[property] = value;
            return element.style[property] === value;
        }
        return true;
    }
    return false;
}
