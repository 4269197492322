/**
 * @file 工具函数
 * @author chengong03(chengong03@baidu.com)
 * @date 2018-11-23
 */
import qs from 'qs';
import md5 from 'md5.js';
import {query, queryAll, position, getScrollTop} from '@/utils/dom';
import {isIOS, isBB} from '@/utils/ua';
import {getCacheData, setCacheData} from '@/utils/cache';

/**
 * accessid key
 *
 * @const
 * @type {string}
 */
const ACCESSID_CACHE_KEY = 'ACCESSID';

/**
 * 通用延时（毫秒）
 * 超过这个时间，自动删除
 *
 * @const
 * @type {number}
 */
const COMMON_DELAY_TIME = 36e5;

/**
 * CAP_AK
 *
 * @const
 * @type {string}
 */
const CAP_AK = '3biSkLPY1';

/**
 * CAP_SK
 *
 * @const
 * @type {string}
 */
const CAP_SK = '2I7XVuhzr';

/**
 * 数据分组
 *
 * @param  {Array} arr 数字源
 * @param {number} size 每组的数量
 * @return {Array} 计算后的数组
 */
export function chunk(arr, size) {
    let result = [];

    size = window.parseInt(size) || 2;

    for (let x = 0; x < Math.ceil(arr.length / size); x++) {
        let start = x * size;
        let end = start + size;

        result.push(arr.slice(start, end));
    }

    return result;
}

/**
 * 获取 url query
 *
 * @return {Object} url 参数对下
 */
export function getQuery() {
    return qs.parse(window.location.search.slice(1));
}

/**
 * 拼接 url query
 *
 * @param {Object} urlParams 参数对下
 * @return {string} 拼接过的 url string
 */
export function setQuery(urlParams) {
    let query = getQuery();

    let newQuery = {
        ...query,
        ...urlParams
    };

    return qs.stringify(newQuery);
}

export function replaceStateQuery(urlParams) {
    let search = setQuery(urlParams);
    window.history.replaceState(null, '', `?${search}`);
}

/**
 * 删除url query
 *
 * @param  {string} name 删除的参数键名
 * @return {string}      删除指定参数后的queryString
 */
export function delQuery(name) {
    let query = getQuery();
    delete query[name];
    return qs.stringify(query);
}

/**
 * 补全 url
 *
 * @param {string} url 连接地址
 * @return {string}
 */
export function fullUrl(url) {
    if (!url) {
        return '';
    }

    // 如果有协议，则直接返回
    if (/^https?:/.test(url)) {
        return url;
    }

    // 如果以//开头，直接拼上协议头
    if (/^\/\//.test(url)) {
        return window.location.protocol + url;
    }

    // 否则拼上协议头&域名
    return window.location.origin + url;
}

/**
 * 对目标字符串进行html编码
 * 参考：https://github.com/BaiduFE/Tangram-base/blob/master/src/baidu/string/encodeHTML.js
 *
 * @param {string} source 目标字符串
 * @return {string} html编码后的字符串
 */
export function encodeHTML(source) {
    return String(source)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
}

/**
 * 对目标字符串进行html解码
 * 参考：https://github.com/BaiduFE/Tangram-base/blob/master/src/baidu/string/decodeHTML.js
 *
 * @param {string} source 目标字符串
 * @return {string} html解码后的字符串
 */
export function decodeHTML(source) {
    let str = String(source)
        .replace(/&quot;/g, '"')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ');
    // 处理转义的中文和实体字符
    return str.replace(/&#([\d]+);/g, function (_0, _1) {
        return String.fromCharCode(parseInt(_1, 10));
    });
}

/**
 * 异步加载 script
 *
 * @param {string} url 链接
 * @param {Function} callback 回调函数
 * @param {Object} opt script标签属性
 */
export function loadScript(url, callback, opt = {}) {
    let script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    if (opt.charset) {
        script.charset = opt.charset;
    }
    if (opt.id) {
        script.id = opt.id;
    }

    script.onload = function () {
        callback();
    };
    script.onerror = function () {
        callback(new Error('load script err!'));
    };

    script.src = url;
    document.body.appendChild(script);
}

/**
 * 页面垂直平滑滚动到指定滚动高度
 *
 * @param {number} position 高度
 */
export function scrollSmoothTo(position) {
    // 当前滚动高度
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // 滚动step方法
    let step = function () {
        // 距离目标滚动距离
        let distance = position - scrollTop;
        // 目标滚动位置
        scrollTop = scrollTop + distance / 5;
        if (Math.abs(distance) < 1) {
            window.scrollTo(0, position);
        }
        else {
            window.scrollTo(0, scrollTop);
            requestAnimationFrame(step);
        }
    };
    step();
}

/**
 * 页面垂直平滑滚动到指定元素
 *
 * @param {string} selector 元素的selector
 * @param {number} space 预留空间（顶格贴边会很难看）
 */
export function scrollSmoothToEle(selector, space = 0) {
    // 需要滚动到的目标元素距离顶部的高度 position
    let height = position(query(selector)).top - space;
    scrollSmoothTo(height);
}

/**
 * 获取元素在指定容器中的数量
 *
 * @param  {HTMLElement} wrapEle 容器元素
 * @param  {string} itemSelector 子元素选择器
 * @return {number} 展示的数量
 */
export function getInWrapCount(wrapEle, itemSelector) {
    let items = queryAll(itemSelector, wrapEle);
    let wrapHeight = wrapEle.offsetHeight;
    let num = 0;
    items.forEach(item => {
        let pos = position(item, wrapEle);
        if (pos.top < wrapHeight) {
            num++;
        }
    });
    return num;
}

/**
 * 返回数组中满足条件的索引
 *
 * @param  {Array}   array     数组
 * @param  {Function} callback 检测函数
 * @param  {Object}   thisArgs this对象
 * @return {number}            索引值
 */
export function findIndex(array, callback, thisArgs) {
    // 类型校验
    if (!Array.isArray(array)) {
        throw new TypeError('@/utils/util/findIndex: array must be a Array');
    }
    if (typeof callback !== 'function') {
        throw new TypeError('@/utils/util/findIndex: callback must be a Function');
    }

    // 原生方法
    if (Array.prototype.findIndex) {
        return array.findIndex(callback, thisArgs);
    }

    // ployfill
    let index = 0;
    let length = array.length;

    while (index < length) {
        let value = array[index];
        if (callback.call(thisArgs, value, index, array)) {
            return index;
        }
        index++;
    }

    return -1;
}

/**
 * 是否为对象
 *
 * @param {Object} obj 目标对象
 * @return {boolean} 是否为对象
 */
export function isObj(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * 是否为空对象
 *
 * @param {Object} obj 目标对象
 * @return {boolean} 是否为空对象
 */
export function isEmptyObj(obj) {
    return JSON.stringify(obj) === '{}';
}

/**
 * 获取对象指定key或key包含的字符串对应的value
 *
 * @param {Object} obj 目标对象
 * @param {Object} target key或者key包含的字符串
 * @param {boolean} type 指定key 为true，key片段为false
 * @return {Any} result
 */
export function getObjAnyVal(obj, target, type = true) {
    let result;
    Object.keys(obj).map(key => {
        if (type && key === target) {
            result = obj[key];
        }

        if (!type && key.indexOf(target) !== -1) {
            result = obj[key];
        }

        if (isObj(obj[key])) {
            result = getObjAnyVal(obj[key], target);
        }
    });

    return result;
}


/**
 * 深拷贝
 *
 * @param {Object} obj 目标对象
 * @return {Object} 拷贝后的对象
 */
export function deepCopy(obj) {
    return obj && JSON.parse(JSON.stringify(obj));
}

/**
 * 数字前是否添加0
 *
 * @param {number} num 目标数字
 * @return {string} 处理后的数字
 */
export function isAddZero(num) {
    return num < 10 ? '0' + num : num;
}

/**
 * 获取 url 参数
 *
 * @param {string} rawUrl 原始链接
 * @return {Object} query值
 */
export function getUrlQuery(rawUrl) {
    if (!rawUrl) {
        return '';
    }
    const [url] = rawUrl.split('#');
    return qs.parse(url.split('?')[1] || '');
}

/**
 * 拼接 url query
 *
 * @param {string} rawUrl 原始链接
 * @param {Object} urlParams 参数对象
 * @return {string} 拼接过的 url string
 */
export function addUrlQuery(rawUrl, urlParams) {
    if (!rawUrl) {
        return '';
    }
    const [url, hash = ''] = rawUrl.split('#');
    const [path, queryStr = ''] = url.split('?');
    const query = qs.parse(queryStr);

    let newQueryStr = qs.stringify({
        ...query,
        ...urlParams
    });

    return `${path}?${newQueryStr}${hash ? '#' : ''}${hash}`;
}

/**
 * jump url 拼接 timeSign
 * 含 '/b2bsearch/jump?' 字段的广告链接需要拼接 timeSign，统计用
 *
 * @param {string} rawUrl 原始链接
 * @param {string} timeSign timeSign
 * @return {string} 结果url
 */
export function jumpUrlAddTimeSign(rawUrl = '', timeSign = '') {
    // 普通链接直接返回原链接
    if (rawUrl.indexOf('/b2bsearch/jump?') === -1) {
        return rawUrl;
    }

    let query = timeSign ? ('&timeSign=' + timeSign) : '';

    // 注意后端有时会返回含有两个?的链接，如果用 addUrlQuery 方法会导致链接不可用
    return rawUrl + query;
}

/**
 * 生成随机的 pageId
 *
 * @return {string} pageId
 */
export function genPageId() {
    return Math.random().toString().substr(3, 17);
}

/**
 * 读取cookie值
 *
 * @param {string}  name  键名
 * @return {string} 值
 */
export function getCookie(name) {
    if (name) {
        let cookie = ' ' + document.cookie + ';';
        let searchName = ' ' + name + '=';
        let startIndex = cookie.indexOf(searchName);
        if (startIndex !== -1) {
            let endIndex = cookie.substring(startIndex).indexOf(';');
            return cookie.substring(startIndex + searchName.length, startIndex + endIndex);
        }
    }
    return null;
}

/**
 * 判断链接是否为广告链接
 *
 * @param {string} url 是否为广告链接
 * @return {boolean} 是否为广告链接
 */
export function isAdLink(url) {
    return /\.php\?url=([^.]+)\./.test(decodeURIComponent(url));
}

/**
 * 数字千位增加分割逗号
 *
 * @param {number} num 原始数据
 * @return {number} 处理后的数据
 */
export function kSplit(num) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
}

/**
 * 即将进入搜索页时，搜索框能否可以自动聚焦
 *
 * @return {boolean} 是否可以自动聚焦
 */
export function searchDetailAutoFocus() {
    // 安卓永远自动聚焦
    // iOS 在滚动距离较小时自动聚焦，否则会闪抖
    if (isIOS() && getScrollTop() > 44) {
        return false;
    }
    return true;
}

/**
 * 生成uuid
 *
 * @return {string} uuid
 */
export function uuid() {
    let s = [];
    let hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    return s.join('');
}

/**
 * 判断rgb颜色是否为深色
 *
 * @param  {string}  rgb  6位rgb颜色
 * @return {boolean}      是否为深色
 */
export function isColorDark(rgb) {
    let rgbArr = rgb.match(/[a-zA-Z0-9]{2}/g);
    let res = rgbArr.reduce((res, i) => {
        i = parseInt(i, 16) / 255;
        res[0] = Math.max(res[0], i);
        res[1] = Math.min(res[1], i);
        return res;
    }, [0, 1]);
    return (res[0] + res[1]) / 2 < 0.5;
}

let initRouterKey;

/**
 * 初始化首次进入的判断
 */
export function initFirstHistory() {
    initRouterKey = (window.history.state || {}).key || '';
}

/**
 * 判断是否首次进入
 *
 * @return {boolean} 是否首次进入
 */
export function isFirstHistory() {
    if (!initRouterKey) {
        initRouterKey = (window.history.state || {}).key || '';
        return true;
    }

    let key = (window.history.state || {}).key;

    return key === initRouterKey;
}

/**
 * 初始化列表页来自aladdin首次加载完成的标记
 */
export function initAladdinListHistory() {
    setCacheData('FIRSTLOADALADDINLIST', 1);
}

/**
 * 判断是否是首次加载完成来自aladdin的列表
 *
 * @return {boolean} 是否首次加载完成来自aladdin的列表
 */
export function isAladdinListHistory() {
    return !getCacheData('FIRSTLOADALADDINLIST');
}

/**
 * 判断是否是固话
 *
 * @param  {string}  phone 固话号码
 * @return {boolean}  是否是固话
 */
export function isTel(phone) {
    const regTel = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
    return regTel.test(phone);
}

/**
 * 价格格式化
 *
 * @param {boolean} isAd 是否是广告
 * @param {Object} data 价格数据
 * @param {number} digits 保留位数
 * @param {number} specific 当只有min时 显示具体数值 不要 “起”
 * @return {Object} ret 格式化数据
 */
export function formatInvestment(isAd = false, data = {}, digits = -1, specific = false) {
    let ret = {};
    let {min, max, amount, amountUnit} = data || {};
    if (isAd) {
        ret = {
            amount,
            unit: amountUnit
        };
        return ret;
    }
    if (digits > 0) {
        let base = Math.pow(10, digits);
        min = Math.floor(min * base) / base;
        max = Math.floor(max * base) / base;
    }

    if (max && max !== -1) {
        ret = {
            amount: `${ (!min || min === -1) ? 0
            : min}-${max}`,
            unit: '万'
        };
        return ret;
    }
    // specific： 如果需要展示具体的数额 不要 “起”
    ret = {
        amount: `${ (!min || min === -1) ? 0
                : min}`,
        unit: specific ? '万' : '万起'
    };

    return ret;
}

/**
 *针对IOS系统,解决Input中的focus问题[IOS系统默认在用户没有发生交互前拒绝自动聚焦]
 *
 * @export
 * @param {*} el    DOM节点
 * @param {*} timeout   延迟出现的时间
 */
export function focusAndOpenKeyboard(el, timeout) {
    let time;
    if (!timeout) {
        time = 500;
    }
    if (el) {
        time = timeout;
        // Align temp input element approximately where the input element is
        // so the cursor doesn't jump around
        const __tempEl__ = document.createElement('input');
        __tempEl__.style.position = 'absolute';
        __tempEl__.style.top = (el.offsetTop + 7) + 'px';
        __tempEl__.style.left = el.offsetLeft + 'px';
        __tempEl__.style.height = 0;
        __tempEl__.style.opacity = 0;
        // Put this temp element as a child of the page <body> and focus on it
        document.body.appendChild(__tempEl__);
        __tempEl__.focus();

        // The keyboard is open. Now do a delayed focus on the target element
        setTimeout(function () {
            el.focus();
            el.click();
            // Remove the temp element
            document.body.removeChild(__tempEl__);
        }, time);
    }
}

// set accessid
export function setAccessid({add = false}) {
    let accessidObj  = getCacheData(ACCESSID_CACHE_KEY);
    const now = new Date().getTime();
    // 页面没改变一次，深度+1
    if (add && accessidObj && accessidObj.value) {
        accessidObj.value[2]++;
    }

    // 如果没有accessidObj || 超过设定时间 重新set
    if (!accessidObj
        || accessidObj && (now - accessidObj.time > COMMON_DELAY_TIME)) {
        const accessid = window._tplData && window._tplData.trace.logid || '';
        accessidObj = {
            value: [accessid, 'D', 0],
            time: new Date().getTime()
        };
    }

    setCacheData(ACCESSID_CACHE_KEY, accessidObj);
}

/**
 * get accessid
 *
 * @return {Array} value
 */
export function getAccessid() {
    const accessidObj  = getCacheData(ACCESSID_CACHE_KEY);
    return accessidObj && accessidObj.value || [];
}

/**
 * 节流函数
 *
 * @param  {Function} func  执行的函数
 * @param  {number} delay 延迟
 * @return {Function}       包装的节流函数
 */
export const debounce = (func, delay) => {
    let timer = null;

    return () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func.apply(this);
            timer = null;
        }, delay);
    };
};

/**
 * 判断是否是spider监控
 *
 * @return {boolean} 是/否
 */
export function isSpider() {
    let userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('spider/monitor') > -1;
}

/**
 * 初始化表单Json 缓存
 *
 * @param  {boolean} version json 版本
 * @param  {Array} list  数组对象
 */
export function setInquiryFormCache(version, list = []) {
    setCacheData('INQUIRYFORMJSON', {version, cacheList: list});
}

/**
 * 获取表单Json 缓存
 *
 * @return {Object} 表单Json
 */
export function getInquiryFormCache() {
    return getCacheData('INQUIRYFORMJSON') || {};
}

/**
 * set使用哪种搜索结果页
 *
 * @param  {String} name 列表页名称
 * @return {string} 搜过结果页类型
 */
export function setSearchResultList(name) {
    return setCacheData('SET_SEARCH_RESULT_LIST', name);
}

/**
 * 获取使用哪种搜索结果页
 *
 * @return {string} 搜过结果页类型
 */
export function getSearchResultList() {
    return getCacheData('SET_SEARCH_RESULT_LIST') || 'List';
}

/**
 * 获取网络类型
 *
 */
export function getNetworkType(callback) {
    // 手百环境使用协议获取网络类型
    if (isBB()) {
        // 加上时间戳，防止同时有多个组件调用函数
        const cbKey = 'get_network_type_cb' + new Date().getTime();
        // 注册手百回调函数
        window[cbKey] = function (res) {
            try {
                const objRes = typeof res === 'string' ? JSON.parse(res) : res;
                callback(objRes && objRes.data && objRes.data.networkType || '');
            }
            catch (e) {
                callback();
            }
            finally {
                // 移除手百回调函数
                delete window[cbKey];
            }
        };
        // 调起协议
        let node = document.createElement('iframe');
        node.style.display = 'none';
        node.src = 'baiduboxapp://v19/utils/getNetworkType?callback=' + cbKey;
        let body = document.body || document.getElementByTagName('body')[0];
        body.appendChild(node);
        // 销毁iframe
        setTimeout(() => {
            body.removeChild(node);
            node = null;
        }, 0);
    }
    else {
        // 浏览器环境使用connection对象获取网络类型
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        const connNetType = connection && connection.type;
        // 大搜环境使用ecom对象获取网络类型
        const ecomParams = window.ecom && window.ecom.wise && window.ecom.wise.PARAMS;
        const ecomNetType = (ecomParams && ecomParams.netType === '1') ? 'wifi' : ''; // netType为1时代表wifi
        // 优先使用浏览器connection对象获取网络类型
        callback(connNetType || ecomNetType);
    }
}

/**
 * 搜索词高亮
 *
 */
export function brightenKeyWord(val, keyword) {
    if(!keyword) return val;
    const Reg = new RegExp(keyword, 'g');
    if (val) {
        return val.replace(Reg, `<strong>${keyword}</strong>`)
    }
}

/**
 * 格式化时间
 *
 */
export function secondToDate(allSecond) {
    let retShow = '';
    if (+allSecond) {
        let h = Math.floor(+allSecond / 3600);
        let m = Math.floor((+allSecond / 60 % 60));
        let s = Math.floor((+allSecond % 60));
        if (h) {
            let showH = timeToStringShow(h);
            retShow = retShow + showH + ':';
            !m && (retShow += '00');
            !s && (retShow += ':00');
        }
        if (m) {
            let showM = timeToStringShow(m);
            retShow = retShow + showM + ':';
            !s && (retShow += '00');
        }
        if (s) {
            !m && (retShow = retShow + '00'+ ':')
            let showS = timeToStringShow(s);
            retShow = retShow + showS;
        }
    }
    return retShow;
}

/**
 * 时间格式化
 *
 */
export function timeToStringShow(time) {
    let ret = '';
    if (+time > 0 && +time < 10) {
        ret = '0' + time;
    }
    else if (+time >= 10 && +time <= 59) {
        ret = time;
    }
    return ret;
}

/**
 * 设置二维码缓存
 *
 * @param {String} key 二维码type inquiry 咨询弹窗
 * @param {Object} res 二维码相关内容
 */
 export function setQRcodeCache(key, res) {
    let cacheObj = getCacheData('QRCODE_DATA') || {};
    cacheObj[key] = {...res};
    setCacheData('QRCODE_DATA', cacheObj);
}

/**
 * 获取二维码缓存
 *
 * @param {String} key 二维码type inquiry 咨询弹窗
 * @return {Object} 二维码内容
 */
export function getQRcodeCache(key) {
    if (key) {
        return getCacheData('QRCODE_DATA') && getCacheData('QRCODE_DATA')[key] || '';
    }

    return getCacheData('QRCODE_DATA') || {};
}

/**
 * 驼峰转横线分割
 * aBcd -> a-bcd
 */
export function camelToLine(name) {
    const str = name.replace(/([A-Z])/g,"-$1").toLowerCase();
    return str.replace(/^(-)/, "");
}

export function checkCaptcha(str) {
   return new md5().update(str).digest('hex');
}

export function getCapAkSk() {
    return CAP_AK.substr(0, CAP_AK.length - 1) + CAP_SK.substr(1);
}

/**
 * 生成指定长度的随机字符串
 *
 */
export function randomString(length) {
    const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) {
        result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
}


/**
 * json字符串转json对象
 * @param { String } jsonStr json字符串
 */
/* eslint-disable no-eval */
export function parseJson(jsonStr){
    return JSON.parse(jsonStr, (k, v) => {
        try{
        // 将正则字符串转成正则对象
        if (eval(v) instanceof RegExp) {
            return eval(v);
        }
        }catch(e){
        // nothing
        }

        return v;
    });
}
/* eslint-disable no-eval */

/**
 * json对象转json字符串
 * @param { Object } json json对象
 */
export function stringifyJson(json){
    return JSON.stringify(json, (k, v) => {
        // 将正则对象转换为正则字符串
        if(v instanceof RegExp){
        return v.toString();
        }

        return v;
    });
}

export function getQueryString(params, name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const r = params.match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

/**
 * 替换掉html标签里面的br标签
 * 
 * @param {string} src 
 * @returns 
 */
function removeBrTag(src = '') {
    return src ? src.replaceAll('<br>', '') : '';
}

/**
 * 替换掉html标签里面的img标签
 * 
 * @param {string} src 
 * @returns 
 */
function removeImgTag(src = '') {
    let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
    return src ? src.replaceAll(imgReg, '') : '';
}

/**
 * 针对多个标签嵌套的情况，手工移除掉前面的起始标签
 * 
 * @param {string} src
 * @return
 */
function replaceStartTag(src) {
    let regEx = new RegExp("<[a-zA-Z]*?>([\\s\\S]*?)", 'g');
    return src ? src.replaceAll(regEx, '') : '';
}

/**
 * 获取html中的数据
 * @param {string} htmlStr
 * @return
 */
export function getCotentFromHtml(htmlStr) {
    // 匹配html标签，例如"<p>xxx</p>"这种格式
    const HTML_TAG_PATTERN = new RegExp('<[a-zA-Z]+.*?>([\\s\\S]*?)</[a-zA-Z]*?>', 'g');
    let res = [];
    // 数据预处理
    let str = replaceStyle(removeImgTag(removeBrTag(htmlStr)));
    let matches = str.matchAll(HTML_TAG_PATTERN);
    for (const match of matches) {
        let matchContent = match[1].trim();
        // 针对多个标签嵌套的情况进行处理
        matchContent = replaceStartTag(matchContent);
        res.push(matchContent);
    }
    return res.join('');
}
/**
 * 格式化为N位，前面补0
 * @param {Number} num 数字
 * @param {Number} n 输出的字符串长度
 */
export function prefixInteger(num, n) {
    return num.toString().padStart(n, '0');
}
