/**
 * @file vue router
 * @author wangjiaqi(wangjiaqi17@baidu.com)
 * @date 2020-05-11
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

// 攻略
const StrategyDetail = () => import(/* webpackChunkName: "Strategy" */ '@/views/Strategy/Detail.vue');

// 线路
// const WayDetail = () => import(/* webpackChunkName: "Way" */ '@/views/Way/Detail.vue');
// POI详情页
const PoiDetail = () => import(/* webpackChunkName: "Poi" */ '@/views/POI/Detail.vue');

Vue.use(VueRouter)

// META 配置使用说明
// ├── title 标题
// ├── bottomBar 底部 Bar 配置
// └── bodyBg 背景颜色

const routes = [
	{
		path: '/strategy/detail/:id',
		name: 'StrategyDetail',
		component: StrategyDetail,
		meta: {
			title: '攻略详情页'
		}
	},
	// {
	// 	path: '/way/detail',
	// 	name: 'WayDetail',
	// 	component: WayDetail,
	// 	meta: {
	// 		title: '线路详情页'
	// 	}
	// },
	{
		path: '/poi/detail/:id',
		name: 'PoiDetail',
		component: PoiDetail,
		meta: {
			title: 'POI详情页'
		}
	},
	
]

const router = new VueRouter({
	mode: 'history',
	base: 'm',
	routes
})

export default router
