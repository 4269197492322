/**
 * @file 初始化站点的主入口(ly)
 * @author wangjaiqi17@baidu.com
 */

/* globals _hmt */
import Vue from 'vue';
import App from '@/views/App';
// import VConsole from 'vconsole';

import {setLog, log} from '@/utils/log';
import {genPageId} from '@/utils/util';
import Router from 'vue-router';
import VueClipboard from 'vue-clipboard2';
import store from './store'

import durationLogMixin from '@/mixins/durationLogMixin';

import(/* webpackChunkName: "iconfont" */'@/assets/font/iconfont.js');

// 引入全局样式
import '@/assets/font/fonts.css';
// 引入 MUI 样式
import 'm-ui/comm.less';
import $toast from 'm-ui/plugins/toast';

// 移动端调试可开启
// new VConsole();

Vue.config.productionTip = false;

// 声明为 wise 平台，方便 Search UI 做样式定制
Vue.prototype.$platform = 'wise';
// 声明 Search UI 是 lightmode 还是 darkmode
Vue.prototype.$theme = 'lightmode';

// 全局toast
Vue.prototype.$toast = $toast;

// 剪切板
Vue.use(VueClipboard);

// 全局 mixin
Vue.mixin(durationLogMixin);

const routerMethods = ['push', 'replace'];

export default router => {
    // vue-router push replace 进行跳转时，Uncaught (in promise) undefined异常捕获
    routerMethods.map(method => {
        const originalCall = Router.prototype[method];
        Router.prototype[method] = function (location, onResolve, onReject) {
            if (onResolve || onReject) {
                return originalCall.call(this, location, onResolve, onReject);
            }
            return originalCall.call(this, location).catch(err => err);
        };
    });
    router.beforeEach((to, from, next) => {

        // 设置body背景色
        if (from.meta.bodyBg !== to.meta.bodyBg) {
            document.body.style.background = to.meta.bodyBg || '#fff';
        }

        const toName = to.name;
        setLog({
            page: toName,
            pageid: genPageId()
        });

        // 记录用户停留时间
        if (from.name) {
            log({
                act: 'duration',
                statetime: new Date().getTime() - store.state.pageStartTime
            })
            store.commit('SET_DURATION_STATE', true);
        }

        // 设置当前页面进入时间
        store.commit('SET_PAGE_START_TIME', new Date().getTime());
        store.commit('SET_DURATION_STATE', false);

        next();
    });
    
    /* eslint-disable no-new */
    new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
    /* eslint-enable no-new */
};
