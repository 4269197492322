/**
 * @file  log.js 打点
 * @author chengong03(chengong03@baidu.com)
 * @date 2019-01-09
 */
import Vue from 'vue';
import qs from 'qs';
import throttle from 'lodash/throttle';
import axios from './ajax';
import {getQuery, getCookie} from '@/utils/util';
import {getOS, isBBMp} from '@/utils/ua';
import {getCacheData, setCacheData, delCacheData} from '@/utils/cache';

/**
 * 日志路径
 *
 * @const
 * @type {string}
 */
const LOG_URL = 'https://istats.baidu.com/report/a.gif';

/**
 * ABTest日志路径
 *
 * @const
 * @type {string}
 */
const ABTEST_LOG_URL = (window.location.hostname === 'jiameng.baidu.com'
    && 'https://istats.baidu.com'
    || 'http://10.138.36.83:8999') + '/api/open/v1/report/';
/**
 * 原始来源ofr key
 *
 * @const
 * @type {string}
 */
const OFR_CACHE_KEY = 'OFR';

/**
 * 原始来源延时（毫秒）
 * 超过这个时间，自动删除原始来源
 *
 * @const
 * @type {number}
 */
const OFR_DELAY_TIME = 36e5;

/**
 * 原始FROM搜索from key
 *
 * @const
 * @type {string}
 */
const FROM_CACHE_KEY = 'FROM';

/**
 * 原始FROM延时（毫秒）
 * 超过这个时间，自动删除原始来源
 *
 * @const
 * @type {number}
 */
const FROM_DELAY_TIME = 36e5;

// 初始打点参数
let initLogParams = {
    tpl: 'ly',
    biz: isBBMp() ? 'swan' : 'wise',
    os: getOS()
};

/**
 * 发送日志
 *
 * @param {string=} url 打点地址
 * @param {Object} params 打点参数
 */
const sendLog = (() => {
    const list = [];

    return (url, params) => {
        let logUrl = url + '?' + qs.stringify(params);

        if (navigator.sendBeacon) {
            let retryTimes = 5;
            while (retryTimes-- > 0 && !navigator.sendBeacon(logUrl)) {
                // 加入队列失败时，重试5次
            }
        }
        else {
            let index = list.push(document.createElement('img')) - 1;

            list[index].onload = list[index].onerror = list[index].onabort = function () {
                list[index] = list[index].onload = list[index].onerror = list[index].onabort = null;
                delete list[index];
            };
            list[index].src = logUrl;
        }
    };
})();

/**
 * 设置原始来源
 *
 * @param {Object.string=} from 原始from
 * @param {Object.string=} ofr ofr 原始搜from索来源
 */
export function setOfrLog({from, ofr}) {
    if (!from && !ofr) {
        return;
    }
    if (from) {
        setCacheData(FROM_CACHE_KEY, {
            name: from,
            time: new Date().getTime()
        });
    }

    if (ofr) {
        setCacheData(OFR_CACHE_KEY, {
            name: ofr,
            time: new Date().getTime()
        });
    }

}

/**
 * 查看 ofr 日志是否需要打
 */
function checkOfrLog() {
    let ofrObj = getCacheData(OFR_CACHE_KEY);
    let fromObj = getCacheData(FROM_CACHE_KEY);
    const now = new Date().getTime();
    // 如果超过设定时间，则删除 ofr 日志
    if (ofrObj && ofrObj.name) {
        if (now - ofrObj.time > OFR_DELAY_TIME) {
            delLog('ofr');
            delCacheData(OFR_CACHE_KEY);
        }
        // 设置 ofr 日志
        setLog({ofr: ofrObj.name});
    }
    // 如果超过设定时间，则删除 from 日志
    if (fromObj && fromObj.name) {
        if (now - fromObj.time > FROM_DELAY_TIME) {
            delLog('from');
            delCacheData(FROM_CACHE_KEY);
        }

        // 设置from 日志
        setLog({from: fromObj.name});

    }

}

/**
 * jmx打点
 *
 * @param {Object} params 打点参数
 */
export function log(params) {
    // 查看 ofr 日志是否需要打
    checkOfrLog();
    const {logTest, testParams, ...otherParams} = params
    let logParams = {
        _t: new Date().getTime(),
        url: window.location.href,
        ...initLogParams,
        act: 'click',
        ...otherParams
    };

    if (process.env.NODE_ENV === 'development'
        && logParams && (!logParams.page || logParams.page && logParams.page === 'error')
        && window.location.href.indexOf('error') === -1
    ) {
        console.error('打点上报错误,page和url不匹配,请检查上报的打点数据');
    }

    // 有些字段可能从 url 传过来，以 url 为主
    const {origin} = getQuery();

    // 来自哪
    if (origin) {
        logParams.origin = origin;
    }

    // 发送日志
    sendLog(LOG_URL, logParams);

    if (logTest) {
        logABTest(testParams)
    }
}

/**
 * abtest打点
 *
 * @param {Object} params 打点参数
 */
export function logABTest(params) {
    const current = window.currentABTest;
    const logParams = {
        uid: getCookie('BAIDUID'),
        create_at: new Date().getTime(),
        type: params.type,
        ext: params.ext || undefined
    };
    const rid = current && current.rid || params.rid;
    const url = ABTEST_LOG_URL + rid;
    // 发送日志
    sendLogABTest(url, logParams);
}



/**
 * 获取当前日志
 *
 * @return {Object} 日志对象
 */
export function getLog() {
    return initLogParams;
}

// 设置参数，主要用来设置当前页打出的 page 参数，这样不用手动去打了
export function setLog(params) {
    initLogParams = {
        ...initLogParams,
        ...params
    };
}

// 删除参数
export function delLog(key) {
    delete initLogParams[key];
}

/**
 * 是否在可视区域内
 *
 * @param  {HTMLElement} el 需要判断的模块元素
 * @param  {boolean} partiallyVisible 是否是部分出现就可以
 *
 * @return {boolean} 是否在可视区域内
 */
const inViewport = (el, partiallyVisible) => {
    const {top, bottom, left, right} = el.getBoundingClientRect();
    const {innerHeight, innerWidth} = window;

    return partiallyVisible
        ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) 
            && ((left < innerWidth) && (right > 0))
        : top >= 0 && bottom <= innerHeight && left >= 0 && right <= innerWidth;
};

/**
 * 可视区域打点
 *
 * @param  {HTMLElement} ele 待打点模块
 * @param {Object} params 打点参数
 * @param {HTMLElement} scrollElem 待监听的ele外层滚动模块
 * @param {boolean=} partiallyVisible 是否部分出现算展现
 */
function viewAreaLog(ele, params, scrollElem, partiallyVisible = true) {
    if (!ele) return;
    // 第一次就在可视区域
    if (inViewport(ele, partiallyVisible)) {
        params && log(params);
    }
    else {
        const lazyLoading = throttle(() => {
            if (!inViewport(ele, partiallyVisible)) {
                return;
            }

            params && log(params);

            window.removeEventListener('scroll', lazyLoading);
            scrollElem?.removeEventListener('scroll', lazyLoading);
        }, 100);

        window.addEventListener('scroll', lazyLoading);
        scrollElem?.addEventListener('scroll', lazyLoading);
    }
}

/**
 * 轮播打点
 *
 * @param {string} type 类型
 * @param {Array} list 来源
 * @param {number=} prevIndex 上一次统计长度
 * @param {Object=} other 其他参数
 */
export function carouselLog(type, index) {
    log({
        act: 'click',
        elem: `carousel-${type}`,
        pos: index,
        actType: type
    });
}

/**
 * 列表展现打点
 *
 * @param {string} type 类型
 * @param {Array} list 来源
 * @param {number=} prevIndex 上一次统计长度
 * @param {Object=} other 其他参数
 */
export function listLog(type, list, prevIndex = 0, currentPage, other = {}, AEtype) {
    let projectList = [];
    list.forEach((item, index) => {
        let logObj = {
            elem: `${type}-${item.isAd ? 'ad' : 'product'}`,
            adtype: item.ad_type,
            projectidx: prevIndex + index,
            pos: prevIndex + index,
            projectid: item.isAd ? item.product_id : item.project_id || (AEtype && item.guid),
            // 后续打点所有的id都传id即可，之前的projectid和articleid不变。
            id: item.isAd ? item.product_id : item.project_id || (AEtype && item.guid),
            /**
             * 当前打点中logid会跟随接口进行更新，同时回退时点击会上报新的logid，不便找到源头
             * 所以在这里新增listLogId上报，保存列表当页logid，以便list点击时溯源
             * 注意，需要给list的每一个item添加listLogId字段，以便show与click同源
             */
            listLogId: item.listLogId
        };
        if (!item.isAd) {
            logObj.projecttype = item.level || (AEtype && item.channel) || '';
        }
        projectList.push(logObj);
    });
    log({
        act: 'show',
        ...other,
        currentPage: currentPage,
        projectlist: JSON.stringify(projectList)
    });
}

/**
 * 内容中心列表展现打点
 *
 * @param {string} type 类型
 * @param {Array} list 来源
 * @param {number=} prevIndex 上一次统计长度
 */
export function contentListLog(type, list, prevIndex = 0, currentPage, other = {}) {
    let contentList = [];
    list.forEach((item, index) => {
        let logObj = {
            elem: item.product_id ? `${type}-ad` : type,
            contentidx: prevIndex + index,
            contentid: item.displayId,
            projectid: item.product_id,
            listLogId: item.listLogId,
        };
        contentList.push(logObj);
    });
    log({
        act: 'show',
        currentPage: currentPage,
        contentList: JSON.stringify(contentList),
        ...other
    });
}

/**
 * 收藏页展现打点
 *
 * @param {string} type 类型
 * @param {Array} list 来源
 * @param {number=} prevIndex 上一次统计长度
 */
export function heartLog(type, list, prevIndex = 0) {
    let heartList = [];
    list.forEach((item, index) => {
        let logObj = {
            elem: type,
            heartidx: prevIndex + index,
            heartid: item.projects && item.projects.project_id
        };
        heartList.push(logObj);
    });
    log({
        act: 'show',
        heartList: JSON.stringify(heartList)
    });
}

/**
 * 找相似页展现打点
 *
 * @param {string} type 类型
 * @param {Array} list 来源
 * @param {number=} prevIndex 上一次统计长度
 */
export function similarLog(type, list, prevIndex = 0) {
    let similarList = [];
    list.forEach((item, index) => {
        let logObj = {
            elem: type,
            similaridx: prevIndex + index,
            similarid: item.project_id
        };
        similarList.push(logObj);
    });
    log({
        act: 'show',
        heartList: JSON.stringify(similarList)
    });
}

/**
 * 商业曝光回传
 *
 * @param {string} url 回传url
 * @return {Promise} promise
 */
export function sendLogBusinessBack(url) {
    let list = [];
    return new Promise(() => {
        if (navigator.sendBeacon) {
            let retryTimes = 5;
            while (retryTimes-- > 0 && !navigator.sendBeacon(url)) {
                // 加入队列失败时，重试5次
            }
        }
        else {
            let index = list.push(document.createElement('img')) - 1;

            list[index].onload = list[index].onerror = list[index].onabort = function () {
                list[index] = list[index].onload = list[index].onerror = list[index].onabort = null;
                delete list[index];
            };
            list[index].src = url;
        }
    })
}

/**
 * ABTest 实验数据上报
 *
 * @param {string} url url
 * @param {string} params 参数
 */
export function sendLogABTest(url, params) {
    axios.get(url, {
        params
    });
}

// 基础方法
Vue.prototype.$log = log;
Vue.prototype.$logABTest = logABTest;
Vue.prototype.$getLog = getLog;
Vue.prototype.$setLog = setLog;
Vue.prototype.$setOfrLog = setOfrLog;
Vue.prototype.$delLog = delLog;
// 业务封装
Vue.prototype.$viewAreaLog = viewAreaLog;
Vue.prototype.$listLog = listLog;
Vue.prototype.$carouselLog = carouselLog;
Vue.prototype.$contentListLog = contentListLog;
Vue.prototype.$heartLog = heartLog;
Vue.prototype.$similarLog = similarLog;

