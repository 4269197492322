import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 页面时长上报状态
        durationState: false,
        // 页面开始时间
        pageStartTime: 0,
    },
    mutations: {
        // 设置页面时长上报状态
        SET_DURATION_STATE(state, status) { 
            state.durationState = status;
        },
        // 设置页面开始时间
        SET_PAGE_START_TIME(state, time) {
            state.pageStartTime = time;
        }
    },
    actions: {
    },
    modules: {
    }
})
