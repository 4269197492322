/**
 * @file 缓存数据，方便回退后保留现场
 * @author chengong03(chengong03@baidu.com)
 * @date 2018-12-04
 */
import {getScrollTop, setScrollTop} from '@/utils/dom';
import {stringifyJson, parseJson} from '@/utils/util';

/**
 * scrolltop 缓存的 key
 *
 * @const
 * @type {string}
 */
const CACHE_SCROLL_TOP = 'V_TOP_';

/**
 * 数据缓存的 key 前缀
 *
 * @const
 * @type {string}
 */
const CACHE_DATA = 'V_DATA_';

/**
 * 对象数据缓存的 key 前缀
 *
 * @const
 * @type {string}
 */
const META = 'V_META_';

// localStorage 在低版本 iOS Safari 的隐私模式下无法使用，手机Safari浏览器中具体表现是
// - localStorage对象仍然存在
// - 但是 setItem 会报异常：QuotaExceededError
// - getItem 和 removeItem 直接忽略
const {sessionStorage: sessionStore, localStorage: localStore} = window;

/**
 * 缓存 scrollTop
 *
 * @public
 * @param {string} key key
 * @return {boolean} 是否设置成功
 */
export function setCacheScroll(key) {
    try {
        const scrollTop = getScrollTop();
        sessionStore.setItem(CACHE_SCROLL_TOP + key, scrollTop);
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 重新设置 scrollTop
 *
 * @public
 * @param {string} key key
 * @return {boolean} 是否设置成功
 */
export function restoreCacheScroll(key) {
    try {
        const initScrollTop = +sessionStore.getItem(CACHE_SCROLL_TOP + key) || 0;
        if (initScrollTop) {
            setScrollTop(initScrollTop);
        }
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 清理 scrollTop 缓存
 *
 * @public
 * @param {string} key key
 * @return {boolean} 是否设置成功
 */
export function delCacheScroll(key) {
    try {
        sessionStore.removeItem(CACHE_SCROLL_TOP + key);
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 取出缓存的scrollTop
 * 
 * @param {string} key key 缓存的键值
 * @return {Object}
 */
 export function getCacheScroll(key) {
    try {
        const fullKey = CACHE_SCROLL_TOP + key;
        return JSON.parse(sessionStore.getItem(fullKey));
    }
    catch (error) {
        return undefined;
    }
}

/**
 * 缓存列表数据
 *
 * @public
 * @param {string} key key
 * @param {Object} cacheData 数据
 * @param {boolean} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
export function setCacheData(key, cacheData, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        const fullKey = CACHE_DATA + key;
        store.setItem(fullKey, JSON.stringify(cacheData));
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 取出列表数据
 *
 * @public
 * @param {string} key key
 * @param {boolean} useLocal 是否永久缓存
 * @return {Object}
 */
export function getCacheData(key, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        const fullKey = CACHE_DATA + key;
        return (store && store.getItem(fullKey))
            ? JSON.parse(store.getItem(fullKey))
            : undefined;
    }
    catch (error) {
        return undefined;
    }
}

/**
 * 清理 data 缓存
 *
 * @public
 * @param {string} key key
 * @param {boolean} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
export function delCacheData(key, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        const fullKey = CACHE_DATA + key;
        store.removeItem(fullKey);
        return true;
    }
    catch (error) {
        return false;
    }
}


/**
 * 设置对象缓存的信息
 *
 * @param {string} key key
 * @param {string} query query
 * @param {string} val val
 * @param {boolean} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
export function setCacheMeta(key, query, val, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        let item = store.getItem(META + key) || '{}';
        let queryObj = JSON.parse(item);
        queryObj[query] = val;
        store.setItem(META + key, JSON.stringify(queryObj));
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 获取对象缓存的信息
 *
 * @param {string} key key
 * @param {string} query query
 * @param {boolean} useLocal 是否永久缓存
 * @return {string}
 */
export function getCacheMeta(key, query, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        let item = store.getItem(META + key) || '{}';
        const queryObj = JSON.parse(item);

        if (queryObj[query]) {
            return queryObj[query];
        }

        return '';
    }
    catch (error) {
        return '';
    }
}

/**
 * 删除对象缓存的信息
 *
 * @param {string} key key
 * @param {string} query query
 * @param {boolean} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
export function delCacheMeta(key, query, useLocal = false) {
    try {
        setCacheMeta(key, query, '', useLocal);
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 取出列表数据
 *
 * @public
 * @param {string} key key
 * @param {boolean} useLocal 是否永久缓存
 * @return {Object}
 */
 export function getCacheRegData(key, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        const fullKey = CACHE_DATA + key;
        return (store && store.getItem(fullKey))
            ? parseJson(store.getItem(fullKey))
            : undefined;
    }
    catch (error) {
        return undefined;
    }
}

/**
 * 缓存列表数据
 *
 * @public
 * @param {string} key key
 * @param {Object} cacheData 数据
 * @param {boolean} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
 export function setCacheRegData(key, cacheData, useLocal = false) {
    const store = useLocal ? localStore : sessionStore;
    try {
        const fullKey = CACHE_DATA + key;
        store.setItem(fullKey, stringifyJson(cacheData));
        return true;
    }
    catch (error) {
        return false;
    }
}

/**
 * 生成 urlKey
 *
 * @return {string} 生成的 key
 */
export function genUrlKey() {
    return decodeURI(location.pathname) + location.search;
}