/**
 * @file UserAgent
 * @author chengong03(chengong03@baidu.com)
 */
/* globals Box */
// 浏览器 ua
const ua = window.navigator.userAgent;

/**
 * 是否为 iOS 系统
 *
 * @return {boolean} 是否为特定系统
 */
export function isIOS() {
    return /(iPhone|iPod|iPad)/.test(ua);
}

/**
 * 是否为 Android 系统
 *
 * @return {boolean} 是否为特定系统
 */
export function isAndroid() {
    // 爱采购卖家版的 ua 只有 Android 这个字母，需要注意
    return /Android/.test(ua);
}

/**
 * 是否为 Android 系统
 *
 * @return {string} 特定系统
 */
export function getOS() {
    if (isIOS()) {
        return 'ios';
    }
    else if (isAndroid()) {
        return 'android';
    }
    return '';
}

/**
 * 是否为简搜
 *
 * @return {boolean} 是否为特定系统
 */
export function isSC() {
    return /SearchCraft/.test(ua);
}

/**
 * 是否为手百
 *
 * @return {boolean} 是否为特定系统
 */
export function isBB() {
    return /baiduboxapp/.test(ua);
}

/**
 * 是否为手百小程序
 * 1. 在开发者工具内是没有 /baiduboxapp/
 * 2. 百度小程序只能在手百打开
 *
 * @return {boolean} 是否为特定系统
 */
export function isBBMp() {
    return /swan-baiduboxapp/.test(ua);
}

/**
 * 是否为微信
 *
 * @return {boolean} 是否为特定系统
 */
export function isWx() {
    return /MicroMessenger/.test(ua);
}

/**
 * 是否为微信小程序
 *
 * @return {boolean} 是否为特定系统
 */
export function isWxMp() {
    return isWx() && (/miniProgram/.test(ua) || window.__wxjs_environment === 'miniprogram');
}

/**
 * 是否为小程序
 *
 * @return {boolean} 是否为特定系统
 */
export function isMp() {
    return isBBMp() || isWxMp();
}

/**
 * 是否为 PWA
 *
 * @return {boolean} 是否为特定系统
 */
export function isPWA() {
    return (window.matchMedia('(display-mode: standalone)').matches);
}
