/**
 * @file 通用配置文件
 * @author wangjiaqi17@baidu.com
 */

/**
 * 不需要统计PV的页面列表
 *
 * @const
 * @type {string}
 */
export const NO_PV_LOG_PAGES = [];


/**
 * 不需要将 logId 存到全局变量的 Ajax 列表（不然会污染正常请求的 logid）
 *
 * @const
 * @type {string}
 */
export const NO_LOGID_AJAX = [
    // 待添加
];

